$font-primary: #929495;
$font-red: #ed1c24;
$font-dark: #6b6c6e;
$raleway: 'Raleway', sans-serif;
$opensans: 'Open Sans', sans-serif;

body {
    background: white;
    color: $font-primary;
    font-family: $opensans;
    font-size: 1em;
    line-height: 1.2em;
}
h1,h2,h3,h4,h5,h6 {
    font-family: $raleway;
    color: $font-dark;
}
a:link, a:visited {
    color: $font-dark;
}
a:hover, a:active {
    color: $font-red;
    text-decoration: none;
}
.link-primary:link, .link-primary:visited {
    color: $font-primary;
}
.link-primary:hover, .link-primary:active {
    color: $font-red;
}
/**** HEADER ****/
nav.navbar {
    font-family: $raleway;
}

/**** EDIT BOOTSTRAP ****/
/* navbar */
.navbar-light .navbar-nav .active > .nav-link {
    color: $font-red;
}
.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
}
.navbar-light .navbar-nav .nav-link {
    color: $font-primary;
}
.navbar-light .navbar-nav .nav-link:hover {
    color: $font-red;
}
/* buttons */
.btn {
    border-radius: 0;
    font-size: .9em;
    padding: 1em 0;
}
.btn-info:link, .btn-info:visited {
    color: #fff;
    background-color: $font-primary;
    border-color: $font-primary;
}
.btn-info:hover, .btn-info:active {
    color: #ffffff;
    background-color: $font-red;
    border-color: $font-red;
}
.btn-primary {
     color: #fff;
     background-color: $font-red;
     border-color: $font-red;
}
.btn-primary:hover {
    color: #fff;
    background-color: $font-dark;
    border-color: $font-dark;
}
/**** breadcrumb ****/
.breadcrumb {
    background-color: #fff;
    border-radius: 0;
    padding: 1rem 0;
}
.breadcrumb-item.active {
    color: $font-red;
}
ol.breadcrumb a {
    color: $font-primary;

    &:hover {
        color: $font-red;
    }
}
/**** contact ****/
.form-control {
    padding: 1.5rem 0.75rem;
    border-radius: 0 !important;
}
/**** CARD - VELUX ****/
.card {
    border-radius: 0;

    &:hover h4 {
        color: $font-red;
    }
}
.card-img-top {
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
/**** HOMEPAGE ****/
.homepage-box {
    box-shadow: 0.188em 0.25em 2.188em rgba(0,0,0,0.16);
}
.homepage-box img {
    width: 200px;
}
.homepage-box p {
    font-family: $raleway;
    font-weight: 600;
}
.homepage-box .col-md-4:hover p {
    color: $font-red;
}
.negative-margin {
    margin-top: -25px;
}
.bg-quality {
    position: absolute;
    top: 20%;
    right: 0;
    display: none;
}
@media (min-width: 992px) {
    .bg-quality {
        display: block;
    }
}
.red-text {
    color: $font-red;
}
/**** FOOTER ****/
.footer-logo {
    max-width: 340px;
}
.footer-nav {
    ul {
        list-style-type: none;
        li {
            display: inline-block;
            a {
                display: inline-block;
                padding-right: 1em;
            }
            a:link, a:visited{
                text-decoration: none;
                color: $font-primary;
            }
            a:hover, a:active{
                color: $font-red;
            }
        }

    }
}
footer .fas {
    font-size: 18px;
    color: $font-red;
}
footer .fab {
    color: $font-red;
}
.payment {
    max-width: 250px;
}
footer a {
    color: $font-red;
}
footer a:hover {
    color: $font-dark;
    text-decoration: none;
}
.velux-photo {
    max-width: 200px;
}

/**** ABOUT US ****/
.line {
    width: 150px;
}

.reference {
    text-align: center;
    border: 1px solid #ddd;
}
.reference:hover {
    background: #dddddd;
    color: #fff;
}
.reference p {
    margin-bottom: 0;
}
.about-us a {
    color: $font-red;
}
/**** VELUX PRODUCTS ****/
.red-icon i {
    color: $font-red;
    font-size: 16px;
}
/**** CRYPTED MAIL ****/
.cryptedmail:after {
    content: attr(data-name) "@" attr(data-domain) "." attr(data-tld);
}
